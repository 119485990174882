<template>
  <div id="reserve_details">
    <HeadNav />
    <div class="loading" v-show="img_show">
      <img src="@/static/image/loading.gif" class="loading-img" />
    </div>
    <yd-infinitescroll
      :callback="getGoodsList"
      ref="collect"
      v-show="!img_show || list.length > 0"
    >
      <div slot="list">
        <ul v-for="(item, index) in list" :key="index">
          <li class="details">
            <span class="details_sty details_title">领用明细</span>
            <span class="details_content details_name">
              {{ item.goods_order_detail[0].name }}...
              <img
                src="@/static/image/jiantoushang.png"
                class="arrow"
                v-if="!is_show[index]"
                @click="getShow(index)"
              />
              <img
                src="@/static/image/jiantouxia.png"
                class="arrow"
                v-if="is_show[index]"
                @click="getShow(index)"
              />
            </span>
          </li>
          <template v-if="is_show[index]">
            <div
              class="show-content"
              v-for="(data, keys) in item.goods_order_detail"
              :key="keys"
            >
              <span class="content-left">{{ data.name }}</span>
              <span class="content-right"> x{{ data.goods_num }}</span>
            </div>
          </template>
          <li>
            <span class="details_title">申请时间</span>
            <span class="details_content">{{
              formData(item.create_time)
            }}</span>
          </li>
          <li>
            <span class="details_title">审批状态</span>
            <span class="details_content">
              {{ getStatus(item.is_pass) }}
              <i
                class="withdraw"
                v-if="item.is_pass == '0'"
                @click="withdraw(item.id)"
                >撤销
                <img src="@/static/image/chexiao.png" />
              </i>
            </span>
          </li>
          <li>
            <span class="details_title">审核时间</span>
            <span
              class="details_content"
              v-if="item.is_pass == 2 || item.is_pass == 1"
              >{{ formData(item.update_time) }}</span
            >
          </li>
        </ul>
      </div>
    </yd-infinitescroll>
    <!-- 空视图 -->
    <div class="empty-box" v-if="list.length === 0 && is_request">
      <Empty tip="暂无申请数据～"></Empty>
    </div>
  </div>
</template>

<script>
import Empty from "@/components/empty/Empty";
import { getGoodsList, getCancelOrder } from "@/services/my";
import HeadNav from "@/components/common/HeadNav.vue";

export default {
  components: {
    Empty,
    HeadNav
  },
  data() {
    return {
      page: 1, // 开始查询的下标
      listRows: 10, // 每次查询的数量
      list: [],
      is_show: [],
      is_request: 0,
      img_show: true //加载中
    };
  },
  inject: ["reload"],
  created() {
    this.is_show = this.list.map(item => false);
  },
  mounted() {
    this.getGoodsList();
  },
  methods: {
    //加载列表
    async getGoodsList() {
      let params = {
        page: this.page,
        listRows: this.listRows
      };
      const res = await getGoodsList(params);
      this.is_request = 1;
      if (res.code == 0) {
        this.img_show = false;
        const data = res.data;
        this.list = [...this.list, ...data.list];
        this.total = data.total;
        if (this.list.length === data.total) {
          /* 所有数据加载完毕 */
          this.page++;
          this.$refs.collect.$emit("ydui.infinitescroll.loadedDone");
          return;
        }
        /* 单次请求数据完毕 */
        this.page++;
        this.$refs.collect.$emit("ydui.infinitescroll.finishLoad");
      }
    },
    //撤销
    async withdraw(id) {
      const res = await getCancelOrder(id);
      console.log(res.code);
      this.$dialog.toast({
        mes: res.msg,
        timeout: 1500
      });
      if (res.code == 0) {
        setTimeout(() => {
          this.reload();
        }, 1600);
      }
    },
    //审批情况
    getStatus(s) {
      let status = "";
      switch (s) {
        case 0:
          status = "待审批";
          break;
        case 1:
          status = "已通过";
          break;
        case 2:
          status = "已拒绝";
          break;
        case 3:
          status = "已取消";
          break;
        default:
          status = "已拒绝";
          break;
      }
      return status;
    },
    //是否展开
    getShow(idx) {
      this.is_show[idx]
        ? this.$set(this.is_show, idx, false)
        : this.$set(this.is_show, idx, true);
    },
    // 不足10数字前加0
    timeJudge(t) {
      if (t < 10) {
        return "0" + t;
      } else {
        return t;
      }
    },
    // 时间戳转日期格式
    formData(t) {
      const time = new Date(t * 1000);
      const year = time.getFullYear();
      const month = time.getMonth() + 1;
      const date = time.getDate();
      const hour = time.getHours();
      const minute = time.getMinutes();
      const second = time.getSeconds();
      return (
        year +
        "-" +
        this.timeJudge(month) +
        "-" +
        this.timeJudge(date) +
        " " +
        this.timeJudge(hour) +
        ":" +
        this.timeJudge(minute) +
        ":" +
        this.timeJudge(second)
      );
    }
  }
};
</script>

<style lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fafafa;
  .loading-img {
    position: relative;
    width: 4rem;
    height: 4rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
#reserve_details {
  .withdraw {
    font-size: 0.24rem;
    color: #409eff;
    padding-left: 0.3rem;
    position: relative;
    img {
      position: absolute;
      width: 0.26rem;
      top: 0;
      left: 0;
    }
  }
  .yd-list-donetip {
    display: none;
  }
  /* 列表为空*/

  .empty-box {
    background: @color-white;
    height: 100vh;
  }
  ul {
    border-bottom: 1px solid #eee;
    padding: 0.3rem 0.2rem;
    background: #fff;
    .show-content {
      color: #fff;
      background: #409eff;
      font-size: 0.24rem;
      padding: 0.1rem 0;
      border-radius: 0.1rem;
      margin: 0.06rem 0;
      span {
        display: inline-block;
      }
      .content-left {
        padding-left: 10%;
        width: 65%;
      }
      .content-right {
        width: 35%;
        text-align: center;
      }
    }
    .details {
      min-height: 0.7rem;
      // line-height: 0.7rem;
      height: 100%;
      position: relative;
    }
    li {
      height: 0.46rem;
      line-height: 0.46rem;
      color: #999;
      font-size: 0.24rem;
      display: flex;
      align-items: center;
      .details_title {
        width: 30%;
      }
      span {
        display: inline-block;
      }
      .details_name {
        color: #000;
        font-size: 0.28rem;
      }
      .arrow {
        width: 0.26rem;
        position: absolute;
        right: 0.4rem;
        top: 0rem;
        margin: 0.2rem;
      }
      .details_sty {
        width: 1.5rem !important;
        float: left;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 0.26rem;
        background: #409eff;
        position: relative;
        border-radius: 0.06rem;
        color: #fff;
        margin-right: 9%;
      }
      .details_content {
        max-width: 60%;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
