var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "reserve_details" } },
    [
      _c("HeadNav"),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.img_show,
              expression: "img_show",
            },
          ],
          staticClass: "loading",
        },
        [
          _c("img", {
            staticClass: "loading-img",
            attrs: { src: require("@/static/image/loading.gif") },
          }),
        ]
      ),
      _c(
        "yd-infinitescroll",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.img_show || _vm.list.length > 0,
              expression: "!img_show || list.length > 0",
            },
          ],
          ref: "collect",
          attrs: { callback: _vm.getGoodsList },
        },
        [
          _c(
            "div",
            { attrs: { slot: "list" }, slot: "list" },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "ul",
                { key: index },
                [
                  _c("li", { staticClass: "details" }, [
                    _c("span", { staticClass: "details_sty details_title" }, [
                      _vm._v("领用明细"),
                    ]),
                    _c(
                      "span",
                      { staticClass: "details_content details_name" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.goods_order_detail[0].name) +
                            "...\n            "
                        ),
                        !_vm.is_show[index]
                          ? _c("img", {
                              staticClass: "arrow",
                              attrs: {
                                src: require("@/static/image/jiantoushang.png"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getShow(index)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.is_show[index]
                          ? _c("img", {
                              staticClass: "arrow",
                              attrs: {
                                src: require("@/static/image/jiantouxia.png"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getShow(index)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _vm.is_show[index]
                    ? _vm._l(item.goods_order_detail, function (data, keys) {
                        return _c(
                          "div",
                          { key: keys, staticClass: "show-content" },
                          [
                            _c("span", { staticClass: "content-left" }, [
                              _vm._v(_vm._s(data.name)),
                            ]),
                            _c("span", { staticClass: "content-right" }, [
                              _vm._v(" x" + _vm._s(data.goods_num)),
                            ]),
                          ]
                        )
                      })
                    : _vm._e(),
                  _c("li", [
                    _c("span", { staticClass: "details_title" }, [
                      _vm._v("申请时间"),
                    ]),
                    _c("span", { staticClass: "details_content" }, [
                      _vm._v(_vm._s(_vm.formData(item.create_time))),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", { staticClass: "details_title" }, [
                      _vm._v("审批状态"),
                    ]),
                    _c("span", { staticClass: "details_content" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.getStatus(item.is_pass)) +
                          "\n            "
                      ),
                      item.is_pass == "0"
                        ? _c(
                            "i",
                            {
                              staticClass: "withdraw",
                              on: {
                                click: function ($event) {
                                  return _vm.withdraw(item.id)
                                },
                              },
                            },
                            [
                              _vm._v("撤销\n              "),
                              _c("img", {
                                attrs: {
                                  src: require("@/static/image/chexiao.png"),
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", { staticClass: "details_title" }, [
                      _vm._v("审核时间"),
                    ]),
                    item.is_pass == 2 || item.is_pass == 1
                      ? _c("span", { staticClass: "details_content" }, [
                          _vm._v(_vm._s(_vm.formData(item.update_time))),
                        ])
                      : _vm._e(),
                  ]),
                ],
                2
              )
            }),
            0
          ),
        ]
      ),
      _vm.list.length === 0 && _vm.is_request
        ? _c(
            "div",
            { staticClass: "empty-box" },
            [_c("Empty", { attrs: { tip: "暂无申请数据～" } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }